<template>
  <v-app>
    <v-main>
      <v-container class="d-flex justify-center align-center fill-height" style="flex-direction: column;">
        <div class="logo-text" style="color: white">ADrak<span style="color: red;">Net</span></div>

        <div class="logo mb-4">
          <div class="logo-section logo-wallets">
            <span>WALLETS</span>
          </div>
          <div class="logo-section logo-pay">
            <span>PAY</span>
          </div>
        </div>

        <v-card class="pa-6" max-width="500">
          <v-card-title class="justify-center">Dados da Transação</v-card-title>
          <v-card-text>
            <v-stepper v-model="step">
              <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">Dados do Cliente</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2">Dados do Comércio</v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form ref="formStep1" v-model="validStep1" lazy-validation>
                    <v-text-field label="E-mail do Cliente" v-model="email" :rules="[rules.required, rules.email]"
                      required></v-text-field>
                    <v-text-field label="Valor" v-model="valor" type="number" required prefix="R$"
                      :rules="[rules.required, rules.positiveNumber]"></v-text-field>
                    <div v-if="btcValue">
                      <p>Valor em BTC: {{ btcValue }} BTC</p>
                    </div>
                  </v-form>
                  <v-btn color="#e30000" block @click="validateStep1">Próximo</v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-form ref="formStep2" v-model="validStep2" lazy-validation>
                    <v-select v-model="tipoChavePix" :items="['Celular', 'CPF/CNPJ', 'E-mail', 'Aleatória']"
                      label="Tipo da chave PIX" required :rules="[rules.required]" @change="applyPixMask"></v-select>
                    <v-text-field label="Chave PIX" v-model="chavePix" :rules="[rules.required]" required
                      hint="Preencha de acordo com o tipo de chave selecionada" persistent-hint></v-text-field>
                    <v-text-field label="Sobrenome / Razão Social" v-model="sobrenomeRazaoSocial" required
                      :rules="[rules.required]"></v-text-field>
                  </v-form>

                  <v-divider></v-divider>

                  <div v-if="valor" class="my-4">
                    <h3 class="subtotal-text">SUBTOTAL: R$ {{ subtotal }}</h3>
                  </div>

                  <v-row>
                    <v-col>
                      <v-btn color="gray" block class="mr-2" @click="prevStep">Voltar</v-btn>
                    </v-col>
                    <v-col>
                      <v-btn color="#e30000" block @click="openWarningModal" :loading="loading">Finalizar</v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>
        </v-card>

        <!-- Warning Modal -->
        <v-dialog v-model="showWarningModal" max-width="500">
          <v-card>
            <v-card-title class="text-h5 justify-center">Aviso Importante</v-card-title>
            <v-card-text class="text-center py-5">
              <p class="mb-4">Importante! Nossa carteira utiliza a rede Bitcoin ON-CHAIN.</p>
              <p class="mb-4">Pagamentos via Lightning Network não são compatíveis</p>
              <p class="mb-4">Transações On-chain podem levar alguns minutos para confirmação</p>
              <p class="mb-4">Para garantir que seu pagamento seja processado corretamente, utilize apenas a rede
                Bitcoin tradicional.</p>
              <p>Caso tenha dúvidas, estamos à disposição no cleringsapame@proton.me para ajudar!</p>
            </v-card-text>
            <v-card-actions class="justify-center pb-6">
              <v-btn color="#e30000" @click="acceptWarning">Aceitar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- QR Code Modal -->
        <v-dialog v-model="showQRCodeModal" max-width="400">
          <v-card>
            <v-card-title class="text-h5 justify-center">QR Code para Pagamento</v-card-title>
            <v-card-text class="text-center py-5">
              <div class="btc-amount mb-4">
                <p class="text-h6 font-weight-bold">Total a pagar:</p>
                <p class="text-h5 red--text">{{ btcValue }} BTC</p>
              </div>
              <div class="qr-code-container">
                <img src="https://i.postimg.cc/wMkvgPTy/qrcode.jpg" alt="QR Code" class="qr-code-image" />
              </div>
              <v-btn color="primary" class="mt-4" @click="copyToClipboard">
                <v-icon left>mdi-content-copy</v-icon>
                Copiar QR Code
              </v-btn>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeQRCodeModal">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="snackbarColor" timeout="3000">
          {{ snackbarText }}
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import { createClient } from "@supabase/supabase-js";
import validator from 'validator';
import DOMPurify from 'dompurify';

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL;
const supabaseKey = process.env.VUE_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export default {
  name: "App",

  data() {
    return {
      step: 1,
      email: "",
      valor: "",
      tipoChavePix: "",
      chavePix: "",
      sobrenomeRazaoSocial: "",
      btcValue: null,
      btcRate: null,
      validStep1: false,
      validStep2: false,
      showWarningModal: false,
      showQRCodeModal: false,
      loading: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success",
      walletCode: "1HGEHcgJWXCG17Mk9sx6kUZcNNAsSC1Avd",
      currentTransactionId: null,
      rules: {
        required: value => !!value || "Campo obrigatório.",
        email: value => {
          if (!value) return true;
          return validator.isEmail(value) || "E-mail inválido.";
        },
        positiveNumber: value => {
          const number = parseFloat(value);
          return (number > 0 && number <= 1000000) || "O valor deve ser positivo e menor que R$ 1.000.000.";
        },
        cpfCnpj: value => {
          if (this.tipoChavePix === "CPF/CNPJ") {
            return validator.isTaxID(value, 'pt-BR') || "CPF/CNPJ inválido.";
          }
          return true;
        },
        celular: value => {
          if (this.tipoChavePix === "Celular") {
            return validator.isMobilePhone(value, 'pt-BR') || "Número de celular inválido.";
          }
          return true;
        }
      }
    };
  },

  computed: {
    subtotal() {
      return (this.valor - this.valor * 0.05).toFixed(2);
    }
  },

  methods: {
    async fetchBTCRate() {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=brl"
        );
        this.btcRate = response.data.bitcoin.brl;
        this.convertToBTC();
      } catch (error) {
        console.error("Erro ao buscar a taxa de conversão BTC:", error);
      }
    },
    convertToBTC() {
      if (this.valor && this.btcRate) {
        this.btcValue = (this.valor / this.btcRate).toFixed(8);
      } else {
        this.btcValue = null;
      }
    },
    validateStep1() {
      if (this.$refs.formStep1.validate()) {
        this.step++;
      }
    },
    openWarningModal() {
      if (!this.$refs.formStep2.validate()) return;
      this.showWarningModal = true;
    },
    async acceptWarning() {
      this.showWarningModal = false;
      this.loading = true;

      try {
        const sanitizedEmail = DOMPurify.sanitize(this.email);
        const sanitizedPixKey = DOMPurify.sanitize(this.chavePix);
        const sanitizedBusinessName = DOMPurify.sanitize(this.sobrenomeRazaoSocial);

        const { data, error } = await supabase
          .from("transactions_history")
          .insert([
            {
              email: sanitizedEmail,
              amount_brl: parseFloat(this.valor),
              amount_btc: parseFloat(this.btcValue),
              status: "aguardando",
              pix_key_type: this.tipoChavePix,
              pix_key: sanitizedPixKey,
              business_name: sanitizedBusinessName,
              subtotal: parseFloat(this.subtotal),
              payment_type: 'qr_code'
            }
          ])
          .select();

        if (error) throw error;

        if (data && data[0]) {
          this.currentTransactionId = data[0].id;
        }

        this.showQRCodeModal = true;
        this.showSnackbar("Transação registrada com sucesso!", "success");
      } catch (error) {
        console.error("Erro ao salvar dados:", error);
        this.showSnackbar("Erro ao processar sua transação. Por favor, tente novamente.", "error");
      } finally {
        this.loading = false;
      }
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.walletCode);
        this.showSnackbar("Código copiado com sucesso!", "success");
      } catch (err) {
        this.showSnackbar("Erro ao copiar o código", "error");
      }
    },
    closeQRCodeModal() {
      this.showQRCodeModal = false;
      this.resetForm();
    },
    resetForm() {
      this.email = "";
      this.valor = "";
      this.tipoChavePix = "";
      this.chavePix = "";
      this.sobrenomeRazaoSocial = "";
      this.btcValue = null;
      this.step = 1;
      this.currentTransactionId = null;
      if (this.$refs.formStep1) this.$refs.formStep1.reset();
      if (this.$refs.formStep2) this.$refs.formStep2.reset();
    },
    showSnackbar(text, color = "success") {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    applyPixMask() {
      this.chavePix = "";
    }
  },

  watch: {
    valor() {
      this.convertToBTC();
    },
    tipoChavePix() {
      this.chavePix = "";
    }
  },

  mounted() {
    this.fetchBTCRate();
  },
};
</script>

<style>
.fill-height {
  min-height: 100vh;
}

.subtotal-text {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.logo-text {
  font-family: "Arial", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
  user-select: none;
}

.logo {
  display: flex;
  justify-content: center;
  width: 300px;
  margin-bottom: 20px;
  user-select: none;
}

.logo-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5px 0;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: white;
  user-select: none;
  box-sizing: border-box;
  text-align: center;
  margin: 0;
  padding: 0;
}

.logo-wallets {
  background-color: black;
}

.logo-pay {
  background-color: #e30000;
}

.logo-wallets span {
  color: #e30000;
}

.logo-pay span {
  color: white;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.qr-code-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.btc-amount {
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 16px;
}

@media screen and (max-width: 500px) {
  .qr-code-image {
    width: 180px;
    height: 180px;
  }
}
</style>